export default {
    about: "About",
    openSource: "Open Source",
    photography: "Photography",
    music: "Music",
    stories: "Short stories",
    publicSpeaking: "Public Speaking",
    illustration: "Illustration",
    todayIsSunday: "Today is Sunday",
    webcomic: "The Uprising",
    blog: "Blog",
};
