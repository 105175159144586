import React from "react";
import { Link } from "gatsby";

type Props = {
    title: string;
    to?: string;
} & React.HTMLProps<any>;

const Blade = ({
    title,
    to,
    children,
    className = "bg-gray-900",
    ...props
}: Props) => {
    const Title = ({ children }) => (
        <h1 className="text-2xl lg:text-4xl m-0 uppercase font-hairline font-sans text-white text-center lg:rotate-180">
            {children}
        </h1>
    );

    return (
        <header
            className={`w-14 shadow-2xl  hover:bg-gray-700 relative lg:h-screen text-center p-2 px-5 lg:writing-mode-vertical  ${className}`}
            {...props}
        >
            {children}
            {to ? (
                <Link to={to} className="no-underline">
                    <Title>{title}</Title>
                </Link>
            ) : (
                <Title>{title}</Title>
            )}
        </header>
    );
};

export default Blade;
