const ABOUT = `/about`;

const OPEN_SOURCE = `/open-source`;

const PHOTOSETS = `/photography`;
const PHOTOSET = (id) => `${PHOTOSETS}/photosets/${id}`;

const PUBLIC_SPEAKING = `/public-speaking`;

const ILLUSTRATION = `/illustration`;
const MUSIC = `/music`;

const STORIES = `/stories`;
const STORY = (id) => `${STORIES}/${id}`;

const WEBCOMIC = `/webcomic`;
const WEBCOMIC_PANE = (pane = 1) => `${WEBCOMIC}/${pane}`;
const WEBCOMIC_STATISTICS = `${WEBCOMIC}/statistics`;

const TODAY_IS_SUNDAY = `/today-is-sunday`;

const BLOG = `/blogposts`;
const BLOGPOST = (id) => `${BLOG}/${id}`;

module.exports.ABOUT = ABOUT;
module.exports.BLOG = BLOG;
module.exports.BLOGPOST = BLOGPOST;
module.exports.ILLUSTRATION = ILLUSTRATION;
module.exports.STORY = STORY;
module.exports.OPEN_SOURCE = OPEN_SOURCE;
module.exports.PHOTOSET = PHOTOSET;
module.exports.PHOTOSETS = PHOTOSETS;
module.exports.MUSIC = MUSIC;
module.exports.STORIES = STORIES;
module.exports.WEBCOMIC = WEBCOMIC;
module.exports.WEBCOMIC_PANE = WEBCOMIC_PANE;
module.exports.WEBCOMIC_STATISTICS = WEBCOMIC_STATISTICS;
module.exports.PUBLIC_SPEAKING = PUBLIC_SPEAKING;
module.exports.TODAY_IS_SUNDAY = TODAY_IS_SUNDAY;
