import React, { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Blade from "./Blade";
import pageTitles from "../../data/pageTitles";
import {
    ABOUT,
    BLOG,
    ILLUSTRATION,
    MUSIC,
    OPEN_SOURCE,
    PHOTOSETS,
    PUBLIC_SPEAKING,
    STORIES,
    TODAY_IS_SUNDAY,
    WEBCOMIC,
} from "../../routes";
import "./Menu.css";
import useDarkMode from "use-dark-mode";
import Toggle from "react-toggle";
import Headroom from "react-headroom";

const MenuIcon = require("../../images/social/menu.svg");

const menuLinks = [
    { label: pageTitles.about, link: ABOUT },
    { label: pageTitles.openSource, link: OPEN_SOURCE },
    { label: pageTitles.photography, link: PHOTOSETS },
    { label: pageTitles.music, link: MUSIC },
    { label: pageTitles.stories, link: STORIES },
    { label: pageTitles.publicSpeaking, link: PUBLIC_SPEAKING },
    { label: pageTitles.illustration, link: ILLUSTRATION },
    { label: pageTitles.blog, link: BLOG },
    {
        label: pageTitles.webcomic,
        subtitle: "Post apocalyptic webcomic 🇫🇷",
        link: WEBCOMIC,
    },
    {
        label: pageTitles.todayIsSunday,
        subtitle: "In-game renders",
        link: TODAY_IS_SUNDAY,
    },
];

type Props = {
    children: any;
    to?: string;
    className?: string;
    title?: string;
};

const BackButton = ({ to }) => (
    <Link
        to={to}
        className={
            "fixed top-0 bg-gray-800 text-white p-2 rounded-b-lg no-underline font-sans font-bold shadow-lg hover:bg-gray-700 cursor-pointer transition z-40"
        }
        style={{ right: "3rem" }}
    >
        Back
    </Link>
);

const Layout = ({ className = "", children, to, title }: Props) => {
    const data = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    const darkMode = useDarkMode(false, {
        classNameDark: "mode-dark",
        classNameLight: "mode-light",
    });

    const [isHoveringBack, setHoveringBack] = useState(false);
    const [isMenuVisible, setMenuVisible] = useState(false);

    const linkClassName =
        "block no-underline text-sm lg:text-base text-white uppercase m-0 p-3 md:p-5 transition hover:bg-gray-700";

    const Links = ({ className = "", ...props }) => (
        <ul className={`p-0 m-0 list-none font-sans ${className}`} {...props}>
            <li className={`bg-gray-900 flex justify-between ${linkClassName}`}>
                Dark Mode
                <Toggle checked={darkMode.value} onChange={darkMode.toggle} />
            </li>
            {menuLinks.map(({ label, subtitle, link }) => (
                <Link
                    key={label}
                    to={link}
                    partiallyActive={true}
                    getProps={({ isPartiallyCurrent }) => ({
                        className: isPartiallyCurrent
                            ? `${linkClassName} bg-gray-600`
                            : `${linkClassName} bg-gray-800`,
                    })}
                >
                    <li
                        className={
                            "flex flex-row lg:flex-col justify-between w-full"
                        }
                    >
                        <span>{label}</span>
                        <small className={"text-xs normal-case font-bold"}>
                            {subtitle}
                        </small>
                    </li>
                </Link>
            ))}
        </ul>
    );

    const TitleBlade = () => (
        <Blade
            className={"z-30 bg-gray-900"}
            title={data.site.siteMetadata.title}
            to={"/"}
        />
    );

    const BladeMenuIcon = ({ style = {} }) => (
        <MenuIcon
            className={"absolute text-white fill-current"}
            style={{
                ...style,
                height: 50,
                width: 50,
            }}
        />
    );

    const menuWidth = title ? 250 : 175;

    return (
        <>
            <main
                className={`font-serif min-h-full min-w-full hidden lg:flex fixed ${className}`}
            >
                <TitleBlade />
                {title && (
                    <nav
                        className="Menu flex min-h-screen z-20 "
                        onMouseEnter={() => setHoveringBack(true)}
                        onMouseLeave={() => setHoveringBack(false)}
                        style={{
                            position: "relative",
                            left: isHoveringBack ? 0 : -menuWidth,
                            marginRight: -menuWidth,
                        }}
                    >
                        <Links style={{ minWidth: menuWidth }} />
                        <Blade
                            className={"bg-gray-800"}
                            title={isHoveringBack ? "Back" : title}
                            to={to || "/"}
                        >
                            <BladeMenuIcon
                                style={{
                                    top: 20,
                                    left: "calc(50% - 25px)",
                                }}
                            />
                        </Blade>
                    </nav>
                )}
                <div className={"flex w-full h-screen overflow-scroll"}>
                    {to && <BackButton to={to} />}
                    {children}
                </div>
            </main>
            <main className={`flex flex-col font-serif lg:hidden ${className}`}>
                <Headroom className={"z-30"}>
                    <TitleBlade />
                    {title && (
                        <Blade
                            title={title}
                            className={"bg-gray-800"}
                            onClick={() =>
                                setMenuVisible((visible) => !visible)
                            }
                        >
                            <BladeMenuIcon style={{ left: 0, top: 0 }} />
                        </Blade>
                    )}
                    {isMenuVisible && <Links />}
                    {title && (
                        <Blade
                            title={"Back"}
                            className={"bg-gray-700"}
                            to={to || "/"}
                        />
                    )}
                </Headroom>
                {children}
            </main>
            <div id="progress" />
        </>
    );
};

export default Layout;
